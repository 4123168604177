:root {
    --primary-color: var(--static-gray-900);
    --secondary-color: var(--static-gray-50);
    --root-background-color: rgb(248, 248, 248);
    --main-header-bg-color: var(--static-gray-50);
    --main-header-bottom-border-color: var(--static-gray-900);
    --category-color: var(--static-gray-50);
    --category-item-bg-color: var(--static-gray-200);
    --category-item-bg-color-hover: var(--static-gray-200);
    --category-item-font-color: var(--static-gray-900);
    --view-more-button-font-color: var(--static-gray-900);
    --view-more-button-hover-font-color: var(--static-gray-800);
    --hash-tag-color: var(--static-blue-700);
    --footer-background-color: var(--static-gray-900);
    --footer-text-color: var(--static-gray-400);
    --footer-language-input-text-color: var(--static-blue-600);
    --share-button-background:rgb(230,230,230);
    --share-button-hover-background:var(--static-gray-200);
    --view-more-background:rgb(255,255,255);
    --arrow-similar-images:rgb(255,255,255);
    --qrcode-background:rgb(248,248,248);
    --profile-top: var(--gray-200);
    --report-abuse-terms-of-use:rgb(61,48,162);
}