@media (prefers-color-scheme: dark) {
    :root {
        --primary-color: var(--static-gray-900);
        --root-background-color: rgb(29, 29, 29);
        --secondary-color: var(--static-gray-50);
        --main-header-bg-color: var(--static-gray-900);
        --main-header-bottom-border-color: var(--static-gray-700);
        --category-color: var(--static-gray-900);
        --category-item-bg-color: var(--static-gray-800);
        --category-item-bg-color-hover: var(--static-gray-700);
        --view-more-button-font-color: var(--static-gray-100);
        --view-more-button-hover-font-color: var(--static-gray-100);
        --category-item-font-color: var(--static-gray-50);
        --hash-tag-color: var(--static-gray-50);
        --footer-background-color: var(--static-gray-900);
        --footer-text-color: var(--static-gray-400);
        --footer-language-input-text-color: var(--static-blue-600);
        --arrow-similar-images:none;
        --share-button-background: var(--static-gray-700);
        --share-button-hover-background:var(--static-gray-500);
        --view-more-background: rgb(29, 29, 29);
        --qrcode-background:var(--static-gray-700);
        --profile-top: var(--gray-900);
        --report-abuse-terms-of-use:var(--static-gray-50);
    }
}