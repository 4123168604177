:root {
    --static-gray-50: #fafafa;
    --static-gray-75: #f4f4f4;
    --static-gray-100: #eaeaea;
    --static-gray-200: #d3d3d3;
    --static-gray-300: #bcbcbc;
    --static-gray-400: #959595;
    --static-gray-500: #6e6e6e;
    --static-gray-600: #747474;
    --static-gray-700: #505050;
    --static-gray-800: #4b4b4b;
    --static-gray-900: #323232;
    --static-blue-400: #378ef0;
    --static-blue-500: #2680eb;
    --static-blue-600: #1473e6;
    --static-blue-700: #0d66d0;
    --static-red-400: #ec5b62;
    --static-red-500: #e34850;
    --static-red-550: #d3343c;
    --static-red-600: #d7373f;
    --static-red-700: #c9252d;
    --static-orange-400: #f29423;
    --static-orange-500: #e68619;
    --static-orange-600: #da7b11;
    --static-orange-700: #cb6f10;
    --static-green-400: #33ab84;
    --static-green-500: #2d9d78;
    --static-green-600: #268e6c;
    --static-green-700: #12805c;
    --celery-400: rgb(68, 181, 86);
    --celery-500: rgb(61, 167, 78);
    --celery-600: rgb(55, 153, 71);
    --celery-700: rgb(49, 139, 64);
    --chartreuse-400: rgb(133, 208, 68);
    --chartreuse-500: rgb(124, 195, 63);
    --chartreuse-600: rgb(115, 181, 58);
    --chartreuse-700: rgb(106, 168, 52);
    --yellow-400: rgb(223, 191, 0);
    --yellow-500: rgb(210, 178, 0);
    --yellow-600: rgb(196, 166, 0);
    --yellow-700: rgb(183, 153, 0);
    --magenta-400: rgb(216, 55, 144);
    --magenta-500: rgb(206, 39, 131);
    --magenta-600: rgb(188, 28, 116);
    --magenta-700: rgb(174, 14, 102);
    --fuchsia-400: rgb(192, 56, 204);
    --fuchsia-500: rgb(177, 48, 189);
    --fuchsia-600: rgb(162, 40, 173);
    --fuchsia-700: rgb(147, 33, 158);
    --purple-400: rgb(146, 86, 217);
    --purple-500: rgb(134, 76, 204);
    --purple-600: rgb(122, 66, 191);
    --purple-700: rgb(111, 56, 177);
    --indigo-400: rgb(103, 103, 236);
    --indigo-500: rgb(92, 92, 224);
    --indigo-600: rgb(81, 81, 211);
    --indigo-700: rgb(70, 70, 198);
    --seafoam-400: rgb(27, 149, 154);
    --seafoam-500: rgb(22, 135, 140);
    --seafoam-600: rgb(15, 121, 125);
    --seafoam-700: rgb(9, 108, 111);
    --red-400: rgb(227, 72, 80);
    --red-500: rgb(215, 55, 63);
    --red-600: rgb(201, 37, 45);
    --red-700: rgb(187, 18, 26);
    --orange-400: rgb(230, 134, 25);
    --orange-500: rgb(218, 123, 17);
    --orange-600: rgb(203, 111, 16);
    --orange-700: rgb(189, 100, 13);
    --green-400: rgb(45, 157, 120);
    --green-500: rgb(38, 142, 108);
    --green-600: rgb(18, 128, 92);
    --green-700: rgb(16, 113, 84);
    --blue-400: rgb(38, 128, 235);
    --blue-500: rgb(20, 115, 230);
    --blue-600: rgb(13, 102, 208);
    --blue-700: rgb(9, 90, 186);
    --gray-50: rgb(255, 255, 255);
    --gray-75: rgb(250, 250, 250);
    --gray-100: rgb(245, 245, 245);
    --gray-200: rgb(234, 234, 234);
    --gray-300: rgb(225, 225, 225);
    --gray-400: rgb(202, 202, 202);
    --gray-500: rgb(179, 179, 179);
    --gray-600: rgb(142, 142, 142);
    --gray-700: rgb(110, 110, 110);
    --gray-800: rgb(75, 75, 75);
    --gray-900: rgb(44, 44, 44);
}